<template>
  <div class="success root content-wrapper">
    <div class="container text-center">
        <div class="title mt-3 mb-4">ลงทะเบียนสำเร็จ</div>
        <div class="noshop mb-4">สามารถร่วมกิจกรรมได้<br/>ณ ร้านค้าที่ร่วมรายการ</div>
        <img class="my-3" alt="logo" width="168px" src="@/assets/icon/ssclick-shop.png" />
        <div v-if="shopcode=='noshop'">
            <button
                v-on:click="close()"
                style="width: 80%"
                class="btn btn-primary "
                data-tag="close"
            >
                ตกลง
            </button>
        </div>
        <div v-else>
            <button
                v-on:click="ok()"
                style="width: 80%"
                class="btn btn-primary "
                data-tag="ok"
            >
                เข้าลุ้นรางวัล
            </button>
        </div>
      
    </div>
  </div>
</template>

<script>

export default {
    name: "ssclick-register-success",
    props: ["shopcode"],
    data() {
        return {
    
        };
    },
    computed: {
        
    },
    methods: {
        close() {
            window.liff.closeWindow();
        },
        ok(){
            this.$router.replace(`/ssclick/${this.shopcode}/campaign`);
        }
    },
    mounted() {
        window.loading(false);
    },
};
</script>

<style scoped>
    .title {
        font-size: 25px;
        font-weight:bold;
    }
    .noshop{
        font-size: 22px;
    }
    .content-wrapper{
        background-color: rgba(255, 255, 255, 0.85);
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 12px;
        flex: 1;
    }
</style>
