<template>
  <div class="success root content-wrapper">
    <div class="container text-center">
        <!-- <img class="my-5" alt="logo" src="@/assets/check-mark-1.svg" /> -->
        <div>
            <img
                src="@/assets/icon/ssclick-logo.jpeg"
                height="50%"
                width="65%"
            />
        </div>
        <div class="title mt-3 ">
            หมดเขตร่วมสนุกแล้ว
            <br/>
            ขอบคุณที่ให้ความสนใจกิจกรรม
        </div>
        <div class="info">
            สามารถติดตามรายละเอียด
            <br/>
            กิจกรรมถัดไป ได้ที่ LINE OA
            <br/>
            Sermsuk Click
        </div>
        
        <button
            v-on:click="close()"
            style="width: 80%;"
            class="btn btn-primary mt-4"
            data-tag="ok"
        >
            ปิด
        </button>
    </div>
  </div>
</template>

<script>

export default {
    name: "ssclick-congrats",
    props: ["refcode"],
    data() {
        return {
        };
    },
    computed: {

    },
    methods: {
        close(){
            window.liff.closeWindow();
        }
    },
    mounted() {
        window.loading(false);
    },
};
</script>

<style scoped>
    .title {
        font-size: 25px;
        font-weight:bold;
    }
    .info{
        font-size: 20px;
    }
    .refcode{
        font-size: 20px;
    }
    .timer{
        font-size: 26px;
    }
    .content-wrapper{
        background-color: rgba(255, 255, 255, 0.85);
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 12px;
        flex: 1;
        display: inline-table;
    }
</style>
