<template>
  <div class="success root content-wrapper">
    <div class="container text-center">
        <!-- <img class="my-5" alt="logo" src="@/assets/check-mark-1.svg" /> -->
        <div class="mt-4">
            <img
                src="@/assets/icon/ssclick-sad.png"
                height="50%"
                width="50%"
            />
        </div>
        <div class="title mt-3 ">ไม่ต้องเสียใจนะ<br/>พรุ่งนี้มาลองกันใหม่</div>
        <div class="info mb-4 p-4">แต่ถ้าอยากเติมความซ่า<br/>สั่ง "เอส" กับพนักงานร้าน<br />ได้เลย</div>
    
        <button
            v-on:click="ok()"
            style="width: 80%"
            class="btn btn-primary "
            data-tag="ok"
        >
            ปิด
        </button>
        
      
    </div>
  </div>
</template>

<script>

export default {
    name: "ssclick-register-success",
    props: ["shopcode"],
    data() {
        return {
    
        };
    },
    computed: {
        
    },
    methods: {
        ok() {
            window.liff.closeWindow();
        },
    },
    mounted() {
        window.loading(false);
    },
    };
</script>

<style scoped>
    .title {
        font-size: 18pt;
        font-weight:bold;
    }
    .info{
        font-size: 15pt;
    }
    .content-wrapper{
        background-color: rgba(255, 255, 255, 0.85);
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 12px;
        flex: 1;
    }
</style>
